.ac-share-page .ac-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 9vw;
    justify-content: center;
    overflow-y: scroll;
}

.ac-share-page .ac-container p {
    font-size: 6vw;
    margin-bottom: 3vh;
}

.ac-share-page .ac-container .bread {
    margin-bottom: 5vh;
}

.ac-share-page .ac-container .bread a {
    font-family: "FbSpoilerRegular";
    font-size: 5vw;
    text-decoration: none;
    color: #fff;
}

.ac-share-page .ac-sharemesssage {
    border: 0.1vw solid #eaeaea;
    height: 55vh;
    display: flex;
    flex-direction: column;
}

.ac-share-page .ac-img-wrapper {
    width: 78%;
    margin: 4vh auto 2vh;
    height: auto;
}

.ac-share-page .ac-img-wrapper img {
    width: 100%;
    height: auto;
}

.ac-share-page .ac-img-wrapper.ac-car {
    margin-top: -4vw;
    width: 51%;
}

.ac-share-page .ac-sharepage-text {
    text-align: center;
    font-size: 6vw;
    line-height: 1;
    font-family: "FbSpoilerRegular";
}

.ac-share-page .ac-sharepage-text strong {
    font-size: 124%;
    font-family: "FbSpoilerBold";
}

.ac-share-page .ac-message-input {
    width: 100%;
    background: #eaeaea;
    height: 40%;
}

.ac-share-page textarea {
    background: transparent;
    border: 0px;
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
    font-size: 129%;
    height: 100%;
    color: #000;
    font-family: "FbSpoilerRegular";
}

.ac-share-page ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: "FbSpoilerRegular";
    color: #000;
}

.ac-share-page ::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
    font-family: "FbSpoilerRegular";
}

.ac-share-page :-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
    font-family: "FbSpoilerRegular";
}

.ac-share-page :-moz-placeholder {
    /* Firefox 18- */
    color: #000;
    font-family: "FbSpoilerRegular";
}

.ac-share-page .ac-wrapper-btns {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 5vh;
    flex-direction: column;
}

.ac-share-page .ac-wrapper-btns button {
    width: 74%;
    margin: 0 auto 5vw;
    height: 9vw;
    background-color: #fff !important;
    color: #0057c8 !important;
    cursor: pointer !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "FbSpoilerBold" !important;
    font-size: 7vw !important;
    border: 0 !important;
    border-radius: 10px !important;
}

.ac-share-page {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 4vh;
    box-sizing: border-box;
}