.ac-wrapper-popup {
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
    background-color: #000;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
    color: #004b9d;
    position: fixed;
    top: 0;
    left: 0;
}

.ac-wrapper-popup.ac-open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ac-wrapper-popup p {
    text-align: center;
    font-family: "FbSpoilerRegular";
    font-size: 123%;
    margin: 2vh auto;
    color: #fff;
}

.ac-wrapper-popup .ac-inner {
    width: 80%;
    height: auto;
    padding: 3%;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 40px;
    box-sizing: border-box;
    background: #00b2ef;
    background: -moz-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    background: -webkit-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
}

.ac-wrapper-popup .ac-close {
    position: relative;
    width: 100%;
    height: 0;
    margin-bottom: 4vh;
}

.ac-wrapper-popup .ac-close button.ac-close-btn {
    /* width: 30px;
    height: 30px; */
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: #00b8f1;
}

.ac-wrapper-popup.ac-close {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.ac-wrapper-popup.ac-open .ac-img-wrapper {
    width: 95%;
    margin: 0 auto;
}