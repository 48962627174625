.ac-openning-page {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.ac-openning-page .ac-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
}

.ac-openning-page p {
    text-align: center;
    /* font-size: 3.4vh; */
    font-size: 109%;
    font-family: "FbSpoilerRegular";
    letter-spacing: 0.1vw;
}

.ac-openning-page p small {
    line-height: 1;
    /* font-size: 2.1vh; */
    font-size: 80%;
}

.ac-openning-page p:nth-child(3) {
    margin-top: 3%;
    margin-bottom: 5%;
}


/* .ac-openning-page .ac-start-game-btn {
  
    background-color: #fff;
    color: #004fa3;
    border-radius: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin: 0 auto;
    align-items: center;
    text-decoration: none;
    width: max-content;
    height: 7vh;
    padding: 0 6%;
} */


/* .ac-openning-page .ac-start-game-btn .ac-inner-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 4.5vh;
} 

.ac-openning-page .ac-start-game-btn .ac-inner-btn span {
    position: relative;
}

.ac-openning-page .ac-start-game-btn .ac-inner-btn svg {
    fill: #004fa3;
    width: 3vw;
    margin-right: 10px;
} */

.ac-openning-page .ac-img-wrapper {
    margin: 0 auto;
    width: auto;
    height: auto;
    margin-bottom: 1%;
    text-align: center;
    margin-top: 9%;
}

.ac-openning-page .ac-img-wrapper img {
    width: 73%;
    height: auto;
    margin-top: 10%;
}

.ac-openning-page .ac-img-wrapper.ac-car {
    margin-top: 0%;
}

.ac-openning-page .ac-img-wrapper.ac-car img {
    width: 57%;
    margin-top: 0;
}