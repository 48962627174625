.ac-takanon-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11vh;
    overflow: hidden;
    box-sizing: border-box;
    overflow-y: scroll;
}

.ac-takanon-page .ac-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ac-takanon-page .ac-img-wrapper {
    width: 90%;
}

.ac-takanon-page .ac-takanon-text {
    font-size: 7.5vw;
    margin: 4vh auto 2vh;
    font-family: "FbSpoilerRegular";
    text-align: center;
}

.ac-takanon-page .ac-btn.ac-start-game-btn {
    margin: 2vh auto;
}

.ac-takanon-page .ac-btn.ac-start-game-btn .ac-inner-btn {
    padding-bottom: 1vw;
}

.ac-takanon-page .ac-img-wrapper-2 {
    margin-top: 9vh;
}

.ac-takanon-page .ac-takaon-link {
    background-color: #fff;
    color: #0057c8;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin: 0 auto;
    align-items: center;
    text-decoration: none;
    height: 10%;
    padding: 2% 6%;
    width: 73%;
    font-size: 200%;
    font-family: "FbSpoilerBold";
}