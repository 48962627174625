.ac-goals-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0vh;
    overflow: scroll;
    box-sizing: border-box;
}

.ac-goals-page .ac-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ac-goals-page .ac-goalspage-textplace {
    font-size: 6vw;
    font-family: "FbSpoilerRegular";
}

.ac-goals-page .ac-goalspage-text {
    font-size: 11vw;
    font-family: "FbSpoilerBold";
    display: block;
    margin-top: 0vw;
    line-height: 0.7;
    text-align: center;
    margin-bottom: 2.5vh;
}

.ac-goals-page .ac-img-wrapper {
    width: 75%;
}

.ac-goals-page .ac-btn.ac-start-game-btn {
    margin: 2vh auto;
}

.ac-goals-page .ac-btn.ac-start-game-btn .ac-inner-btn {
    padding-bottom: 2vw;
}