.ac-form-page {
    margin-top: -8vw;
    font-family: "FbSpoilerRegular";
    justify-content: center;
    align-items: center;
}

.ac-form-page .ac-img-wrapper {
    width: auto;
    margin: 0 auto;
    height: 27vh;
}

.ac-form-page .ac-img-wrapper img {
    width: auto;
    height: 100%;
    max-width: auto;
    margin: 0 auto;
    display: block;
}

.ac-form-page p {
    margin: 0 0 3vh;
    text-align: center;
    line-height: 1;
    font-size: 7.1vw;
    font-family: "FbSpoilerBold";
}

.ac-form-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
    margin: auto;
}

.ac-form-page label {
    text-align: right;
    margin-right: 11px;
    margin-left: auto;
    width: 100%;
    margin-bottom: 7px;
    font-size: 6vw;
}

.ac-form-page label[for="ac-name"],
.ac-form-page label[for="ac-email"],
.ac-form-page label[for="ac-phone"] {
    display: none;
}

.ac-form-page .ac-form-control {
    margin-bottom: 1.5vh;
    position: relative;
}

.ac-form-page input {
    display: block;
    border-radius: 8px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-size: 5vw;
    padding: 1vh 3vh;
    margin-bottom: 2vh;
    width: 83%;
    height: 9vw;
}

.ac-form-page .round {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.ac-form-page .checkbox {
    display: inline-block;
    width: 3vw;
    height: 3vw;
    background-color: white;
    border: 1px solid #ddd;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    margin: 0 4px;
    border-radius: 50%;
}

.ac-form-page input[type="checkbox"]:checked {
    background-color: black;
    font-family: "FbSpoilerRegular";
}

.ac-form-page .ac-btn {
    background-color: transparent;
    border: white solid 1px;
    color: white;
    margin-top: 0vw;
    font-size: 4vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* height: 6vh; */
    height: auto;
    padding: 0 5%;
}

.ac-form-page .ac-btn svg {
    /* width: 3vw; */
    width: 2.2vw;
    margin-right: 1vw;
    fill: #fff;
}

.ac-addimgpopup input::-webkit-input-placeholder,
.ac-form-page input::-webkit-input-placeholder {
    color: white;
    font-size: 6vw;
}

.ac-addimgpopup input:-ms-input-placeholder,
.ac-form-page input:-ms-input-placeholder {
    color: white;
    font-size: 6vw;
}

.ac-addimgpopup input::placeholder,
.ac-form-page input::placeholder {
    color: white;
    font-size: 6vw;
}

.ac_help_center {
    margin-top: 5vh;
}


/* .ac_help_center { */


/* position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */


/* } */

.ac-form-page .ac-wrapper-popup {
    /* background-color: #004fa3c9; */
    background-color: #00000073;
    z-index: 99;
}


/* .ac-form-page .ac-popup-title {
    position: relative;
    width: 100%;
    height: 16vw;
}

.ac-form-page .ac-popup-title span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'FbSpoilerRegular';
    width: 100%;
    text-align: center;
}

.ac-form-page .ac-popup-title span.ac-title-name-1 {
    -webkit-text-stroke: 3.3vw #004b9d;
    color: #fff;
    font-size: 13vw;
}

.ac-form-page .ac-popup-title span.ac-title-name-2 {
    color: #fff;
    font-size: 13vw;
    -webkit-text-stroke: 0.4vw #fff;
}

.ac-form-page .ac-popup-title p {
    color: #004b9d;
    font-size: 3.5vh;
} */

.ac-form-page #ac-formsent input {
    width: 80%;
    color: #004b9d;
    border: #000 solid 1px;
    margin-bottom: 0vw;
    font-size: 4vw;
}

.ac-form-page #ac-formsent .ac-btn {
    /* width: 93%; */
    width: 90%;
    /* margin-top: 2vh; */
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.ac-en-font {
    font-family: "PlutoCondLight";
}

.ac-form-page #ac-formsent .ac-btn .ac-inner-btn span,
.ac-form-page #ac-img-uploaded .ac-btn .ac-inner-btn span {
    top: 0px;
    /* font-size: 7vw; */
    font-size: 22px;
}

.ac-form-page #ac-img-uploaded .ac-btn svg {
    /* width: 2.5vw;
    position: relative;
    left: -3vw; */
    width: 2vw;
    position: relative;
    left: -2vw;
    top: 0.1vw;
    margin-right: 0vw;
}

.ac-form-page input#file-upload-button {
    visibility: hidden;
}

.ac-form-page .ac-wrapper-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* width: 33%; */
    width: 47%;
    margin: 0 auto;
    margin-bottom: 4vh;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ac-form-page .ac-wrapper-btns button {
    /* width: 45%; */
    width: 26%;
    margin: 0 5px;
    height: auto;
}

.ac-form-page #ac-sms-sent .ac-btn {
    border: #000 solid 1px;
    color: #004fa3;
    margin-top: 2vh;
    padding: 0 9%;
}

.ac-form-page #ac-sms-sent .ac-btn svg {
    fill: #004fa3;
    position: relative;
    left: -3vw;
}

.ac-form-page #ac-sms-sent .ac-btn.ac-btn-darkmode svg {
    fill: #fff;
}

.ac-form-page #ac-sms-sent .ac-btn.ac-btn-darkmode {
    color: #fff;
    padding: 0 9%;
    margin-bottom: 3vh;
}

.ac-form-page #ac-sms-sent .ac-btn.ac-btn-darkmode .ac-inner-btn span,
.ac-form-page #ac-sms-sent .ac-btn .ac-inner-btn span {
    top: 0px;
}

#ac-sms-sent-mobile {
    background-color: transparent !important;
}

.ac-form-page #ac-incorrent-phone-number input {
    color: #004b9d;
    font-size: 6vw;
    text-align: center;
    width: 80%;
    border: 1px solid #004b9d;
}

.ac-form-page #ac-incorrent-phone-number input::-webkit-input-placeholder {
    color: #004b9d;
    font-size: 6vw;
    text-align: center;
}

.ac-form-page #ac-incorrent-phone-number input :-ms-input-placeholder {
    color: #004b9d;
    text-align: center;
    font-size: 6vw;
}

.ac-form-page #ac-incorrent-phone-number input ::placeholder {
    color: #004b9d;
    font-size: 6vw;
    text-align: center;
}

.ac-form-page #ac-incorrent-phone-number svg {
    position: relative;
    left: -3vw;
    width: 2.5vw;
}

.ac-form-page #ac-incorrent-phone-number span {
    top: 0;
}

.ac-form-page #ac-incorrent-phone-number .ac-btn.ac-btn-darkmode {
    margin-bottom: 3vh;
    margin-top: 2vh;
    padding: 0 9%;
}

.ac-form-help:focus-visible {
    outline: none !important;
}


/* .ac-erreur-form:before {
  display: inline;
  content: '⚠ ';
} */

.ac-erreur-form {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -6vw;
    font-size: 3vh !important;
    margin: 0vh !important;
}

.ac-form-page:focus-visible {
    outline: none !important;
}

input:focus-visible {
    outline: none !important;
}

.ac-inverse-color-btn {
    color: #004b9d !important;
    border-color: #004b9d !important;
    background-color: white !important;
}

.ac-btn.ac-btn-darkmode-inverse {
    background-color: white !important;
    color: #004b9d;
    border-color: #004b9d;
}

.ac-btn.ac-btn-darkmode-inverse .ac-inner-btn svg {
    fill: #004b9d;
}

.ac-loader-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.ac-form-page input {
    font-family: "FbSpoilerRegular";
}

.ac-name-update {
    font-size: 8vw !important;
}

.ac-first-shalom {
    height: 8vw !important;
}

.ac-form-page {
    margin-top: 0;
    font-family: "FbSpoilerRegular";
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    box-sizing: border-box;
    padding: 8vh 9%;
    top: 0;
    left: 0;
    overflow-y: scroll;
    justify-content: flex-start;
}

.ac-form-page form button span {
    margin-right: 9%;
}

.ac-form-page form button svg {
    margin-right: 6px;
    width: 5%;
}

.ac-form-page .ac-addimgpopup {
    width: 100%;
    height: 100vh;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00b2ef+0,004fa3+100 */
    background: #00b2ef;
    /* Old browsers */
    background: -moz-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 0vh;
    overflow-y: scroll;
}

.ac-addimgpopup .ac-addimgpopup-ttl {
    display: block;
    text-align: center;
    font-family: "FbSpoilerBold";
    font-size: 15vw;
}

.ac-addimgpopup form {
    margin: 2vh auto;
    border: 0;
}

.ac-addimgpopup form fieldset {
    border: 0;
    width: 100%;
}

.ac-addimgpopup form input {
    width: 70%;
    margin: 0 auto 7vw;
    height: 9vw;
    font-size: 6vw;
}

.ac-addimgpopup form label[for="text_1"] {
    position: relative;
    right: 9vw;
    margin-bottom: 2vw;
    display: block;
    font-size: 7vw;
}

.ac-addimgpopup form label[for="file_1"] {
    visibility: visible;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: transparent;
    border: white solid 1px;
    color: #fff;
    font-size: 6vw;
    padding: 1vh 3vh;
    width: 70%;
    margin: 0 auto 5vw;
    height: 9vw;
}

.ac-addimgpopup form label[for="file_1"] svg {
    margin-left: 12px;
    width: 30px;
    height: 30px;
}

.ac-addimgpopup form .ac-btn-recipe {
    width: 100%;
    height: auto;
    margin-bottom: -7vw;
    text-decoration: underline;
}

.ac-addimgpopup form button[type="submit"] {
    width: 81%;
    margin: auto;
    margin-top: 8vh;
    padding-bottom: 2.5vw;
    box-sizing: border-box;
}

.ac-addimgpopup form input[type="file"] {
    display: none;
}

#ac-thanks-game .ac-content,
#ac-recipe-game .ac-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#ac-thanks-game {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0009;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ac-thanks-game .ac-content {
    width: 80%;
    height: auto;
    padding: 3%;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 40px;
    box-sizing: border-box;
    background: #00b2ef;
    /* Old browsers */
    background: -moz-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

#ac-thanks-game .ac-close {
    width: 100%;
    height: auto;
    position: relative;
}

#ac-thanks-game .ac-close button {
    margin-left: 0;
    margin-right: auto;
    font-size: 25px;
}

#ac-thanks-game.ac-close {
    display: none;
}

#ac-thanks-game .ac-title,
#ac-recipe-game .ac-title {
    width: 100%;
    text-align: center;
    font-size: 11.5vw;
    font-family: "FbSpoilerBold";
    margin-bottom: -1.5vh;
    color: #fff;
}

#ac-recipe-game .ac-title {
    line-height: 0.8;
    display: block;
    margin-bottom: 0;
}

#ac-recipe-game .ac-title2 {
    margin-bottom: 4vw;
    margin-top: 2vw;
}

#ac-thanks-game .ac-text,
#ac-recipe-game .ac-text {
    font-size: 6vw;
    color: #fff;
    margin-bottom: 4vh;
    font-family: "FbSpoilerRegular";
}

#ac-thanks-game .ac-btn,
#ac-recipe-game .ac-btn {
    width: 74%;
    margin-bottom: 3vh;
    margin-top: 4vw;
}

#ac-thanks-game .ac-text,
#ac-recipe-game .ac-text {
    margin-bottom: 0vh;
}

#ac-thanks-game .ac-btn,
#ac-recipe-game .ac-btn {
    background-color: #fff;
    font-size: 4vw;
    width: 69%;
    margin-bottom: 3vh;
}

#ac-recipe-game .ac-close button.ac-close-btn {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: #fff;
    margin-left: 0;
    margin-right: auto;
}

#ac-recipe-game .ac-sms-word-reg {
    font-family: "PlutoCondLight";
    font-size: 88%;
}

#ac-recipe-game .ac-btn .ac-sms-word-bold {
    font-family: "PlutoBold";
    font-size: 75%;
    display: inline-block;
    margin-right: 0%;
}

#ac-recipe-game .ac-btn.ac-start-game-btn .ac-inner-btn {
    display: block;
}

#ac-recipe-game .ac-btn.ac-start-game-btn .ac-inner-btn svg {
    top: 4px;
}

#ac-thanks-game .ac-btn,
#ac-recipe-game .ac-btn {
    font-size: 3.5vw;
    width: 75%;
    margin-bottom: 2vh;
}

#ac-recipe-game .ac-btn.ac-sendsns-again {
    background: transparent;
    height: 11vw;
    font-size: 5.5vw;
    font-family: "FbSpoilerRegular";
    margin-top: 0;
    border: 2px solid #fff;
    padding-bottom: 0.8vw;
}

#ac-recipe-game .ac-text3 {
    margin-bottom: 2vw;
}

#ac-recipe-game {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000ab;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ac-recipe-game .ac-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    padding: 3%;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 40px;
    box-sizing: border-box;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00b2ef+0,004fa3+100 */
    background: #00b2ef;
    /* Old browsers */
    background: -moz-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient( center, ellipse cover, #00b2ef 0%, #004fa3 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #00b2ef 0%, #004fa3 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b2ef', endColorstr='#004fa3', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

#ac-recipe-game .ac-inner span {
    text-align: center;
}

#ac-recipe-game .ac-close-wrapper {
    width: 100%;
    height: auto;
    position: relative;
}

#ac-recipe-game .ac-close-wrapper button {
    margin-left: 0;
    margin-right: auto;
    font-size: 25px;
}

.ac-addimgpopup .ac-goto-recipe-popup {
    position: relative;
    width: 72%;
    height: 0;
}

.ac-addimgpopup .ac-goto-recipe-popup .ac-btn-recipe {
    display: flex;
    width: 100%;
    position: relative;
    top: -28vw;
    font-size: 6vw;
    border-bottom: 1px solid #fff;
    width: auto;
    margin: 0 auto;
    font-family: "FbSpoilerRegular";
}

.ac-addimgpopup button#button_1 span {
    margin-right: 5%;
}

.ac-addimgpopup button#button_1 svg {
    width: 4.5vw;
    height: 4.5vw;
    margin-right: 2vw;
}